import React from "react"
import eventhead from '../../Assets/imgn.jpg'
import eventhead2 from '../../Assets/Yello_Btn_BG.png'
import img1 from '../../Assets/2.jpg'
import eventheadd from '../../Assets/fiximg.png'

const HeroSection3 = () => {
    const divStyle = {
        backgroundImage: `url(${eventheadd})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed"

      };
      const divStyle3 = {
        backgroundImage: `url(${img1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed"

      };
      const divStyle2 = {
        backgroundImage: `url(${eventhead2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

      };
    
      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '620px', // Adjust the width as needed
        paddingBottom: '100px'
      };
    
  return (
    <>

<div style={divStyle3} className="">
    <div className='flex  justify-between gap-20 h-[100vh] flex-wrap pt-12 pb-16' >
    <div style={containerStyle} className="text-white text-right">

      </div>
      <div style={containerStyle} className="text-white text-left">
        <div style={divStyle2} className="py-6 px-10 text-[25px] sm:text-[40px] font-bold w-full uppercase bg-[#faab00]">
        Golden Bay City <br /> Survive or Conquer
        </div>

        <p className=" mt-3 px-2 sm:px-0 sm:w-[85%]">
            <p>
            Battle rival gangs or clean up the streets. Your choices shape the city's destiny.inspired by San Francisco's golden age (1960s). Build your empire, face rival gangs,Police and uncover the city's darkest secrets. In a Single player & Multiplayer Experience
            </p>


        </p>

      </div>
      
    </div>

    </div>
    <div style={divStyle} className="">
    <div className='flex  justify-between gap-20 h-[100vh] flex-wrap pt-12 pb-16' >
    <div style={containerStyle} className="text-white text-right">

      </div>
      <div style={containerStyle} className="text-white text-left">
        <div style={divStyle2} className="py-6 px-10 text-[25px] sm:text-[35px] font-bold w-full mr-[-30px] uppercase bg-[#faab00]">
        G.B.P.D. <br />
        Golden Bay Police Dept.
        </div>

        <p className="space-y-2 mt-3 px-2 sm:px-0 sm:w-[85%]">
            <p>
            From rookie cop to hardened detective, from street hustler to crime lord, everyone has a story to tell.

Join the fight with Action Jackson for power and survival in Golden Bay City.</p>

        </p>

      </div>
      
    </div>

    </div>

    </>
  )
}

export default HeroSection3;