import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay'; // Swiper's autoplay CSS
import { Autoplay } from 'swiper/modules'; // Import Autoplay from the correct path

import lo1 from '../../Assets/lo1.png';
import lo2 from '../../Assets/lo2.png';
import lo3 from '../../Assets/lo3.png';
import lo4 from '../../Assets/lo4.png';
import lo5 from '../../Assets/lo5.png';
import lo6 from '../../Assets/lo6.png';
import lo7 from '../../Assets/lo7.png';
import lo8 from '../../Assets/lo8.png';
import lo9 from '../../Assets/lo9.png';
import lo10 from '../../Assets/lo10.png';
import lo11 from '../../Assets/lo11.png';
import lo12 from '../../Assets/lo12.png';
import lo13 from '../../Assets/lo13.png';
import lo14 from '../../Assets/lo14.png';
import lo15 from '../../Assets/lo15.png';
const ImageSwiper = () => {
  return (
    <div className="flex justify-center bg-black px-2 pt-6 pb-12" id="partnerships">
      <div style={{ width: '100%', maxWidth: '1200px', display: 'flex', justifyContent: 'center' }}>
        <Swiper
          modules={[Autoplay]}  // Add Autoplay module here
          slidesPerView={4}      // default for mobile
          spaceBetween={20}      // Increase space between images
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            640: {
              slidesPerView: 4,   // for tablets
            },
            1024: {
              slidesPerView: 6,   // for laptops
            },
            1440: {
              slidesPerView: 6,   // for desktops
            },
          }}
          loop={true}
          centeredSlides={false}  // Disable centering of slides
          style={{ width: '100%', maxWidth: '1200px' }} // Set Swiper's max width
        >
          <SwiperSlide className='flex justify-center items-center'>
            <img src={lo1} style={{ width: '100px', height: '60px' }} alt="Image 1"  />
          </SwiperSlide>
          {/*<SwiperSlide className='flex justify-center items-center'>
            <img src={lo2} style={{ width: '110px', height: '60px' }} alt="Image 2"  />
          </SwiperSlide>*/}
          <SwiperSlide className='flex justify-center items-center'>
            <img src={lo3} style={{  height: '60px' }} alt="Image 3"  />
          </SwiperSlide>
          <SwiperSlide >
            <img src={lo4} style={{ width: '110px', height: '60px' }} alt="Image 4"  />
          </SwiperSlide>
          <SwiperSlide >
            <img src={lo5} style={{ width: '140px', height: '60px' }} alt="Image 5" />
          </SwiperSlide>
          <SwiperSlide >
            <img src={lo6} style={{ width: '90px', height: '60px' }} alt="Image 5" />
          </SwiperSlide>
          <SwiperSlide >
            <img src={lo7} style={{ width: '60px', height: '60px' }} alt="Image 5" />
          </SwiperSlide>
          <SwiperSlide >
            <img src={lo8} style={{ width: '110px', height: '60px' }} alt="Image 5" />
          </SwiperSlide>
          <SwiperSlide >
            <img src={lo9} style={{ width: '110px', height: '60px' }} alt="Image 5"  />
          </SwiperSlide>
          <SwiperSlide >
            <img src={lo10} style={{ width: '60px', height: '60px' }} alt="Image 5"  />
          </SwiperSlide>
          <SwiperSlide >
            <img src={lo11} style={{ width: '130px', height: '60px' }} alt="Image 5"  />
          </SwiperSlide>
          <SwiperSlide >
            <img src={lo12} style={{ width: '130px', height: '60px' }} alt="Image 5"  />
          </SwiperSlide>
          <SwiperSlide >
            <img src={lo13} style={{ width: '110px', height: '60px' }} alt="Image 5"  />
          </SwiperSlide>
          <SwiperSlide >
            <img src={lo14} style={{ width: '110px', height: '60px' }} alt="Image 5"  />
          </SwiperSlide>
          <SwiperSlide >
            <img src={lo15} style={{ width: '130px', height: '60px' }} alt="Image 5"  />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default ImageSwiper;
