import React, { useState } from "react";
import eventhead from '../../Assets/BG1.png';
import eventhead2 from '../../Assets/RedBtnBG 1.png';
import img1 from '../../Assets/GameTrailer.png';
import img2 from '../../Assets/Weapons.png';
import img3 from '../../Assets/Vehicles.png';
import { Link } from "react-router-dom";

const HeroSection7 = () => {
  // State to track the current video URL
  const [currentVideo, setCurrentVideo] = useState("https://www.youtube.com/embed/OcxIgJQXWhg");

  // Inline styles
  const divStyle = {
    backgroundImage: `url(${eventhead})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
  };
  
  const divStyle2 = {
    backgroundImage: `url(${eventhead2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '200px', // Adjust the width as needed
  };

  // Video sources for each image
  const videos = {
    img1: "https://www.youtube.com/embed/OcxIgJQXWhg", // Default video
    img2: "https://www.youtube.com/embed/YkJE634xmc8?si=m2ZtiEYsF6HD4KEJ",
    img3: "https://www.youtube.com/embed/R7pETalWiLQ?si=_fhMMImSj9zqxsqG",
  };

  return (
    <div>
      <div style={divStyle} className="px-8 py-12 sm:h-[100vh] flex flex-col justify-between">
        <h1 className="text-center text-[20px] sm:text-[40px] pb-4 text-white font-bold">GRAND GANSTA CITY OVERVIEW</h1>
{/*}    <h3 className="text-center text-[30px] text-white font-bold">WEAPONS</h3>
    <h3 className="text-center text-[16px] text-white mt-6 ">Can you imagine dealing a devastating blow with a modified sardine can? For Yaran guerrillas, the answer will always be yes. But resolver weapons are not all you will find in Yara. From a deadly CD launcher to a more classic sniper rifle, there is a weapon for everyone!</h3>
   */}
        {/* Embedded YouTube video */}
        <div className="flex justify-center">
          <iframe
            width="600"
            height="315"
            src={currentVideo} // Video URL based on state
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div className="flex justify-center mt-6">
        <Link to={`https://youtu.be/OcxIgJQXWhg?feature=shared`} target="_blank">
          <button style={divStyle2} className="py-3 px-6 text-white">
            WATCH TRAILER
          </button>
          </Link>
          </div>

        {/* Image gallery */}
        <div className="flex justify-center flex-wrap gap-6 mt-6">
          {/* Clicking on each image changes the video */}
          <div style={containerStyle} className=" cursor-pointer" onClick={() => setCurrentVideo(videos.img1)}>
            <img src={img1} alt="Game Trailer" />
          </div>
          <div style={containerStyle} className=" cursor-pointer" onClick={() => setCurrentVideo(videos.img2)}>
            <img src={img2} alt="Weapons" />
          </div>
          <div style={containerStyle} className=" cursor-pointer" onClick={() => setCurrentVideo(videos.img3)}>
            <img src={img3} alt="Vehicles" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection7;
