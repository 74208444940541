import React from "react"
import eventhead from '../../Assets/bgo.png'
import eventhead2 from '../../Assets/txtimg.png'
import eventhead3 from '../../Assets/mdimg.png'
import eventhead4 from '../../Assets/txtimg2.png'

const HeroSection9 = () => {
    const divStyle = {
        backgroundImage: `url(${eventhead})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed"

      };
      const divStyle3 = {
        backgroundImage: `url(${eventhead3})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed"

      };
      const divStyle2 = {
        backgroundImage: `url(${eventhead2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

      };
      const containerStyle2 = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        width: '200px', // Adjust the width as needed
        
      };
      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        width: '800px', // Adjust the width as needed
        
      };
    
  return (
    <>
    <div style={divStyle} className="px-2 sm:px-24 ">
    <div className='flex  justify-between h-[100vh] flex-wrap ' >
    <div style={containerStyle2} className="text-white text-right">

      </div>
      <div style={containerStyle} className=" text-white text-left">
        <img src={eventhead2} alt="" className="w-[100%] h-[500px]"/>

      </div>
      
    </div>

    </div>

    <div style={divStyle3} className="px-2 sm:px-24 ">
    <div className='flex  justify-between h-[100vh] flex-wrap ' >
    <div style={containerStyle2} className="text-white text-right">

      </div>
      <div style={containerStyle} className="text-white text-left">
        <img src={eventhead4} alt="" className="w-[100%] h-[500px]"/>

      </div>
      
    </div>

    </div>
    </>
  )
}

export default HeroSection9;