import React from 'react'
import img from '../../Assets/Poster.png'
import img2 from '../../Assets/golden.png'
import Footers2 from '../../Components/Footer/Footer2';
import { Navbar2 } from '../../Components/Header/Navbar2';

function About() {
    const divStyle = {
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

      };

      const divStyle2 = {
        backgroundImage: `url(${img2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

      };
    
      const containerStyle = {

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '600px', // Adjust the width as needed
        
      };

  return (
    <div>
      <Navbar2/>
        <div style={divStyle} className="">
    <div className='flex  justify-between gap-2 h-[100vh] flex-wrap pb-16 px-16 montserrat-text uppercase' >
    <div style={containerStyle} className="text-white text-right">

      </div>
      <div style={containerStyle} className="text-white text-right leading-[60px]">
        <span className='font-bold  text-[70px]'>Live</span> <span className=' font-light text-[60px] whitespace-nowrap'> Changing Event</span>

        

      </div>
      
    </div>

    </div>
    <div style={divStyle2} className="montserrat-text">
      <div className='px-6 py-12 flex justify-center'>
    <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-black font-semibold uppercase w-fit">About</p>
    </div>

    <div className='flex justify-center pb-16'>
      <div className="max-w-[1000px] w-[1000px]">
    <p className="py-4 font-normal ">
    At Grand Gangsta City, we focus on merging the best of Web2 and Web3 experiences. Our team is composed of seasoned professionals with decades of combined experience in game development, blockchain technology, and interactive media. We specialize in creating immersive and innovative gaming experiences that leverage the power of blockchain to offer players true ownership of their digital assets.
    </p>
    
    <p className="py-4 font-normal ">
    Our flagship project is a blockchain-based game that combines the thrill of competitive gaming with the transparency and security of decentralized technology. By bridging the gap between traditional gaming and the evolving world of Web3, we aim to create a seamless experience for players, allowing them to enjoy the benefits of both worlds.
        </p>
        <p className="py-4 font-normal ">
        With a commitment to pushing the boundaries of what's possible in gaming, we are dedicated to delivering high-quality, engaging, and innovative products that resonate with players worldwide. At Grand Gangsta City, we are not just building games; we are pioneering the future of interactive entertainment.
                </p>
        </div>
        </div>
    </div>
    <Footers2/>
    </div>
  )
}

export default About