import React from "react"
import eventhead from '../../Assets/img4.png'
import eventhead2 from '../../Assets/Yello_Btn_BG.png'
import img1 from '../../Assets/1.jpg'

const HeroSection2 = () => {
    const divStyle = {
        backgroundImage: `url(${eventhead})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed"

      };
      const divStyle3 = {
        backgroundImage: `url(${img1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed"

      };
      const divStyle2 = {
        backgroundImage: `url(${eventhead2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

      };
    
      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',

        width: '620px', // Adjust the width as needed
        paddingBottom: '100px'
      };
    
  return (
    <>

<div style={divStyle3} className="">
    <div className='flex  justify-between gap-20 h-[100vh] flex-wrap pt-12 pb-16' >
    <div style={containerStyle} className="text-white text-right">

      </div>
      <div style={containerStyle} className="text-white text-left">
        <div style={divStyle2} className="py-6 px-10 text-[25px] sm:text-[40px] font-bold w-full uppercase bg-[#faab00]">
        Cops vs. Gangsta&apos;s <br/> Battle for the City
        </div>

        <p className=" mt-3 px-2 sm:px-0 sm:w-[85%]">
            <p>
            Choose your side: cop or gangster&apos;s. Build your team, fight for your turf, and become the ultimate boss. Experience intense shootouts and strategic gameplay.</p>


        </p>

      </div>
      
    </div>

    </div>



    <div style={divStyle} className="">
    <div className='flex  justify-between gap-20 h-[100vh] flex-wrap pt-12 pb-16' >
    <div style={containerStyle} className="text-white text-right">

      </div>
      <div style={containerStyle} className="text-white text-left">
        <div style={divStyle2} className="py-6 px-10 text-[25px] sm:text-[40px] font-bold w-full bg-[#faab00]">
        G.B.D. <br/> Golden Bay Devils
        </div>
        <p className="mt-3 px-2 sm:px-0 sm:w-[85%]">
          <p>
          From junkies and dancers to bankers and spoiled kids, everyone's got a reason to join the gang.

          Play as these desperate characters, pull off crazy heists, and help the Grandmaster rise to power. Experience immersive storytelling, and unique characters.
          </p>
        </p>

{/*}        <p className="space-y-4 mt-6">
            <p>
        The only thing more dangerous than a gangland boss with the 
world at his fingertips is one who's had it prised from his grasp...
</p>
<p>
We arrive in Gangsta City in the aftermath of Grandmaster's down-fall, with rival gangs engaged in all-out warfare to fill the void left by the 
notorious former Kingpin's demise.
</p>
<p>
However, while the small-time gangs keep each other occupied
fighting over his legacy, Grandmaster is slowly piecing together a 
new, even more, dangerous band of gangstas.
</p>
<ul className="space-y-4">
    <li>
    Its own Integrated Cross-Chain NFTs
    </li>
    <li>
    Play-to-Mine: experience for free
    </li>
    <li>
    Story mode and Battle Royale
    </li>
</ul>
        </p>
*/}
      </div>
      
    </div>

    </div>
    </>
  )
}

export default HeroSection2;