import React, { useState, useEffect } from "react";
import eventhead from '../../Assets/roads.png';

const HeroSection10 = () => {
  const [currentPhase, setCurrentPhase] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector('.roadmap-section');
      const totalHeight = section.scrollHeight;
      const scrollTop = window.pageYOffset - section.offsetTop;

      const phaseHeight = totalHeight / 9; // Assuming there are 5 phases
      const newPhase = Math.min(Math.max(Math.ceil((scrollTop + window.innerHeight / 2) / phaseHeight), 1), 9);
      
      setCurrentPhase(newPhase);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const divStyle = {
    backgroundImage: `url(${eventhead})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed"
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    width: '400px', // Adjust the width as needed
  };

  const containerStyle2 = {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    width: '400px', // Adjust the width as needed
  };
  return (
    <>
      <div className="roadmap-section" style={{ ...divStyle, height: '500vh' }}>
        <div className="px-2 sm:px-32 py-12 sm:h-[100vh]" style={{ position: 'sticky', top: 0 }}>
          <div className='flex justify-center flex-wrap'>
            <div style={containerStyle} className="text-white text-right">
              <p className="border-t-[1px] border-b-[1px] border-gray-500 py-[1px] text-[40px] font-bold">
                THE ROAD MAP
              </p>
            </div>
          </div>

          <div className='flex justify-between flex-wrap '>
            <div style={containerStyle2} className="text-white ">
              <p className="font-bold uppercase text-[60px]">Phase</p>
              <div className="text-[100px] "><p className=" w-fit">{currentPhase < 10 ? `0${currentPhase}` : currentPhase}</p></div>
              <p>
                {currentPhase === 1 && (
                  <>
                  <p>The Foundation (Pre-Alpha)</p>
      <ul style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
        <li>• Whitepaper & Vision</li>
        <li>• Community Building</li>
        <li>• Concept Art & Sneak Peeks:</li>
        <li>• Seed Funding</li>
        <li>• Demo Development</li>
        <li>• Mini-Game Development (Telegram App)</li>
        <li>• Partnerships & Collaborations</li>


      </ul>
                                    </>
                )}
                {currentPhase === 2 && (
                  <>
                  <p>Rise of the City (Alpha/Beta)</p>
      <ul style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
        <li>• Core Gameplay Development</li>
        <li>• Demo Testing</li>
        <li>• Airdrop Campaigns Start</li>
        <li>• Trailer Release for Demo</li>
        <li>• Demo Bringing On-Chain</li>
        <li>• Demo Public Release</li>
        <li>• Marketing & Hype Generation</li>
        <li>• Time Trial Development</li>
        <li>• Partnerships & Collaborations</li>
        <li>• Trailer Release for Time Trial</li>



      </ul>
                                    </>
                )}
                {currentPhase === 3 &&  (
                  <>
                  <p>Grand Opening (Launch)</p>
                  <ul style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
  <li>• Telegram App Game Launch</li>
  <li>• Secondary Market Creation</li>
  <li>• Time Trial Launch</li>
  <li>• Community Events & Tournaments</li>
  <li>• Team Death Match Trailer & Launch</li>
  <li>• Partnerships & Collaborations</li>
  <li>• Battle Royale Development</li>
  <li>• Event in Pakistan in partnerships with Universities & Grand Tournament</li>
  <li>• Youtube Videos By weekly updates of the Studio Team</li>
  <li>• Podcast shows</li>
</ul>
                                    </>
                )}
                {currentPhase === 4 &&  (
                  <>
                  <p>Continuous Evolution (Post-Launch)</p>
                  <ul style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
  <li>• Community-Driven Development: (Allowing Community to vote for Different Maps for Team Death Match & Time Trial)</li>
  <li>• Partnerships & Collaborations</li>
  <li>• Battle Royale Updates</li>
  <li>• Trailer for Battle Royale Development</li>
  <li>• Voting for Battle Royale Maps</li>
  <li>• Development of Quest-Based Game</li>
  <li>• Tournaments For Time Trial & Team Death Match</li>
  <li>• Events in different Universities around the world</li>
  <li>• Youtube Videos with bi-weekly updates from the Studio Team</li>
  <li>• Podcast shows</li>
</ul>
                                    </>
                )}
                {currentPhase === 5 &&  (
                  <>
                  <p>Development & Dedication</p>
                  <ul style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
                  <li>• Battle Royale Updates</li>
  <li>• Release of Community Voted Maps</li>
  <li>• Time Trial</li>
  <li>• Team Death Match</li>
  <li>• Tournaments</li>
  <li>• Partnerships & Collaborations</li>
  <li>• Trailer Release for Battle Royale</li>
  <li>• YouTube Videos with bi-weekly updates from the Studio Team</li>
  <li>• Podcast shows</li>
</ul>
                                    </>
                )}
                {currentPhase === 6 &&  (
                  <>
                  <p>Battle Royale Market Entry</p>
                  <ul style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
                  <li>• Q & A Battle Royale</li>
  <li>• Testing Battle Royale with Community</li>
  <li>• Closed Beta</li>
  <li>• Launch of Battle Royale</li>
  <li>• Tournaments of Battle Royale</li>
  <li>• YouTube Videos with bi-weekly updates from the Studio Team</li>
  <li>• Podcast shows</li>
</ul>
                                    </>
                )}
                {currentPhase === 7 &&  (
                  <>
                  <p>Showcasing the development</p>
                  <ul style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
                  <li>• Quest Based Games Updates</li>
  <li>• Multiple Quest Trailers</li>
  <li>• Battle Royale Release Full</li>
  <li>• Quest Based Testing</li>
  <li>• YouTube Videos with bi-weekly updates from the Studio Team</li>
  <li>• Podcast shows</li>
</ul>
                                    </>
                )}
                {currentPhase === 8 &&  (
                  <>
                  <p>The Quest Master</p>
                  <ul style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
                  <li>• Quest Based Game beta testing</li>
  <li>• YouTube Videos with bi-weekly updates from the Studio Team</li>
  <li>• Podcast shows</li>
</ul>
                                    </>
                )}
                {currentPhase === 9 &&  (
                  <>
                  <p>The Grand Launch</p>
                  <ul style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
  <li>• Grand Launch of the quest Based Game With different events and campaigns</li>
 
</ul>
                                    </>
                )}
              </p>
            </div>
            <div style={containerStyle2} className="text-white text-left mt-[10%]">
              <p className="hidden lg:flex">
                  <>
                    GrandGangstaCity.com: A Phased Approach to Building a Thriving Web3 Metropolis. Grand Gangsta City aspires to be a sprawling, open-world Web3 metropolis where players forge their own destinies. This roadmap outlines potential phases for the development and launch of the game, fostering a vibrant community and a robust in-game economy.
                  </>


              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection10;
